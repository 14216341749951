import en from '@/locales/en.json';
import fi from '@/locales/fi.json';
import vi from '@/locales/vi.json';

export default defineI18nConfig(() => ({
  allowComposition: true,
  fallbackRoot: false,
  legacy: false,
  locale: 'en',
  messages: { en, fi, vi },
}));
